// works returns date time
export var format_datetime = function (unformatted_date, end_of_day) {
    var end_seconds = end_of_day ? 86399999 : 0;
    var frontEndDate = new Date(unformatted_date);
    var tz_offset = frontEndDate.getTimezoneOffset();
    var UTCDate = new Date(Date.parse(frontEndDate.toISOString().split("T")[0]) +
        tz_offset * 60000 +
        end_seconds);
    return UTCDate;
};
export var format_date = function (date) {
    return date.toISOString().split("T")[0];
};

import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import CarriersPicker from "../components/CarriersPicker";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
var CarriersTable = function (_a) {
    var policies_by_carrier = _a.policies_by_carrier, setSelectedCarriers = _a.setSelectedCarriers, selectedCarriers = _a.selectedCarriers, department = _a.department, carriers = _a.carriers;
    var columns = department === "Medicare"
        ? [
            {
                id: "name",
                label: "Insurance Carrier",
            },
            {
                id: "Supplement",
                label: "SUP",
            },
            {
                id: "MAPD",
                label: "MAPD",
            },
            {
                id: "MA",
                label: "MA",
            },
            {
                id: "PDP",
                label: "PDP",
            },
            {
                id: "prem_custom",
                label: "Total Prem",
            },
            {
                id: "written_custom",
                label: "# Written",
            },
        ]
        : department === "IMM"
            ? [
                {
                    id: "name",
                    label: "Insurance Carrier",
                },
                {
                    id: "imm",
                    label: "IMM",
                },
                {
                    id: "stm",
                    label: "STM",
                },
                {
                    id: "dental",
                    label: "Dental",
                },
                {
                    id: "ltc",
                    label: "LTC",
                },
                {
                    id: "vision",
                    label: "Vision",
                },
                {
                    id: "other",
                    label: "other",
                },
                {
                    id: "critical_illness",
                    label: "Critical Illness",
                },
                {
                    id: "health_share",
                    label: "Health Share",
                },
                {
                    id: "prem_custom",
                    label: "Total Prem",
                },
                {
                    id: "written_custom",
                    label: "# Written",
                },
            ]
            : department === "Rosemark"
                ? [
                    {
                        id: "name",
                        label: "Insurance Carrier",
                    },
                    {
                        id: "life",
                        label: "Life",
                    },
                    {
                        id: "financial",
                        label: "Financial",
                    },
                    {
                        id: "prem_custom",
                        label: "Total Prem",
                    },
                    {
                        id: "written_custom",
                        label: "# Written",
                    },
                ]
                : [];
    var colHeaders = department === "Medicare" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { style: headerLeft, align: "center", colSpan: 1 },
            React.createElement("strong", null, "SUP")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "MAPD")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "MA")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "PDP")),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 },
            React.createElement("strong", null, "Total Premium")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "# Written")))) : department === "IMM" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "IMM")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "STM")),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "Dental")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "LTC")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Vision")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Other")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Critical Illness")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Health Share")),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 },
            React.createElement("strong", null, "Total Premium")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "# Written")))) : department === "Rosemark" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "Life")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Financial")),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 },
            React.createElement("strong", null, "Total Premium")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "# Written")))) : (React.createElement(React.Fragment, null));
    return (React.createElement(Paper, null, policies_by_carrier && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, carriers && (React.createElement(CarriersPicker, { carriers: carriers, selectedCarriers: selectedCarriers, setSelectedCarriers: setSelectedCarriers }))),
                    colHeaders)),
            React.createElement(TableBody, null, policies_by_carrier &&
                policies_by_carrier.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.name + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        return (React.createElement(TableCell, { key: row.name +
                                "_" +
                                column.id, style: borders, align: !column.id.includes("insurance_carrier")
                                ? "center"
                                : "left" }, column.id.includes("prem")
                            ? "$" +
                                parseInt(value).toLocaleString("en-US")
                            : value));
                    })));
                })))))));
};
export default CarriersTable;
